<template>
	<div class="main news-detail">
		<NewsHeader :data="data"></NewsHeader>
		<NewsInfo :data="info"></NewsInfo>
	</div>
</template>

<script>
	import NewsHeader from '../../components/news/NewsHeader.vue';
	import NewsInfo from '../../components/news/NewsInfo.vue';
	import { getHuifaHonorInfo,getArticleInfo } from "@/api/index";
	import {
		mapState
	} from 'vuex'
	export default {
		name: "enteringHuifa",
		components:{
			NewsHeader,
			NewsInfo
		},
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
				id:'',
				type:'',
				data:{
					name:'',
					url:''
				},
				info:{}
				
			}
		},
		
		created() {
			this.id=this.$route.params.id
			this.type=this.$route.params.type
			if(this.type=='EnterpriseHonor'){
				this.data.name=this.lang.index_huifa_honor
				this.data.name2=this.lang.huifa_detail
				this.data.url='/enteringHuifa/index/4'
				this.getHuifaHonorInfo()
			}else if(this.type=='PublicCharity'){
				this.data.name=this.lang.index_huifa_welfare
				this.data.name2=this.lang.huifa_detail
				this.data.url='/enteringHuifa/index/5'
				this.getArticleInfo()
			}else if(this.type=='InternalPublications'){
				this.data.name=this.lang.index_huifa_publication
				this.data.name2=this.lang.huifa_detail
				this.data.url='/enteringHuifa/index/6'
				this.getArticleInfo()
			}
		},
		methods:{
			getHuifaHonorInfo(){
				let data = Object.assign({id:this.id}); 
				getHuifaHonorInfo(data).then(res=>{
					this.info=res.data.honor_info
				})
			},
			getArticleInfo(){
				let data = Object.assign({id:this.id});
				getArticleInfo(data).then(res=>{//add_time info title
					this.info=res.data.article_info
				})
			}
		}
	}
</script>

<style lang='less'>
	.news-detail{
		background: linear-gradient(to bottom, #FFE0E0, #F2F2F2),#F2F2F2;
		background-repeat: no-repeat;
		background-size: 100% 25rem;
		padding-bottom: 8rem;
	}
	/* 响应式布局 */
	@media (max-width: 1220px) {
		.news-detail {
		    padding:0 1rem 8rem;
		}
	}
</style>